import { PureComponent } from "react";
import { formatNumber } from "../utils/formatNumber";

export class NumberValue extends PureComponent<{
    value: number;
}> {
    render() {
        const { value } = this.props;

        return <div className="number-value">{formatNumber(value)}</div>;
    }
}
