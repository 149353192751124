import { PureComponent } from "react";
import { observer } from "mobx-react";
import { makeObservable, observable } from "mobx";
import {
    getFaucetBalances,
    getFaucetStatus,
    getFeedStatus,
    getIndexerStatus,
} from "../network/api";
import { blockchainMeta } from "../constants";
import { TimeValue } from "../components/TimeValue";
import { NumberValue } from "../components/NumberValue";

@observer
export class Dashboard extends PureComponent {
    @observable loading: boolean = true;
    @observable indexerData: {
        data: {
            rows: {
                chain: string;
                lastKey: number | null;
                lastMsg: number | null;
                totalKeys: number;
                totalMsgs: number;
                total24Keys: number;
                total24Msgs: number;
            }[];
        };
        criteria: Record<
            string,
            {
                lastKey: null | [number, number];
                lastMsg: null | [number, number];
            }
        >;
    } | null = null;
    @observable feedData: {
        data: {
            rows: {
                chain: string;
                lastMsg: number | null;
                totalMsgs: number;
            }[];
        };
        criteria: Record<
            string,
            {
                lastKey: null | [number, number];
                lastMsg: null | [number, number];
            }
        >;
    } | null = null;
    @observable faucetData: any = null;
    @observable faucetBalances: Record<string, Record<string, number>> = {};

    constructor(props: any) {
        super(props);

        makeObservable(this);
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const [indexerData, feedData, faucetData, faucetBalances] =
            await Promise.all([
                getIndexerStatus(),
                getFeedStatus(),
                getFaucetStatus(),
                getFaucetBalances(),
            ]);
        this.indexerData = indexerData;
        this.feedData = feedData;
        this.faucetData = faucetData;
        this.faucetBalances = faucetBalances;
        this.loading = false;
    }

    render() {
        if (this.loading) {
            return "loading...";
        }

        const sortedIndexerRows = [...this.indexerData!.data.rows];
        sortedIndexerRows.sort((a, b) => b.totalKeys - a.totalKeys);

        const sortedFeedRows = [...this.feedData!.data.rows];
        sortedFeedRows.sort((a, b) => b.totalMsgs - a.totalMsgs);

        return (
            <div className="app">
                <div className="dashboard-block">
                    <h1>Indexer</h1>
                    <div>
                        <table
                            className="table"
                            style={{
                                width: "100%",
                                marginTop: 20,
                            }}
                        >
                            <thead>
                                <tr>
                                    <th>Chain</th>
                                    <th>Last key</th>
                                    <th>Last msg</th>
                                    <th className="number-td">Total keys</th>
                                    <th className="number-td">Total msgs</th>
                                    <th className="number-td">24h keys</th>
                                    <th className="number-td">24h msgs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedIndexerRows.map((row: any) => {
                                    const crit =
                                        this.indexerData!.criteria[row.chain];
                                    return (
                                        <tr key={row.chain}>
                                            <td className="chain-name">
                                                {blockchainMeta[row.chain].logo(
                                                    16
                                                )}{" "}
                                                {
                                                    blockchainMeta[row.chain]
                                                        .title
                                                }
                                            </td>
                                            <td>
                                                <TimeValue
                                                    value={row.lastKey}
                                                    criteria={crit!.lastKey}
                                                />
                                            </td>
                                            <td>
                                                <TimeValue
                                                    value={row.lastMsg}
                                                    criteria={crit!.lastMsg}
                                                />
                                            </td>
                                            <td className="number-td">
                                                <NumberValue
                                                    value={row.totalKeys}
                                                />
                                            </td>
                                            <td className="number-td">
                                                <NumberValue
                                                    value={row.totalMsgs}
                                                />
                                            </td>
                                            <td className="number-td">
                                                <NumberValue
                                                    value={row.total24Keys}
                                                />
                                            </td>
                                            <td className="number-td">
                                                <NumberValue
                                                    value={row.total24Msgs}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                                <tr className="total-row">
                                    <td className="chain-name">Total</td>
                                    <td></td>
                                    <td></td>
                                    <td className="number-td">
                                        <NumberValue
                                            value={sortedIndexerRows.reduce(
                                                (p, c) => p + c.totalKeys,
                                                0
                                            )}
                                        />
                                    </td>
                                    <td className="number-td">
                                        <NumberValue
                                            value={sortedIndexerRows.reduce(
                                                (p, c) => p + c.totalMsgs,
                                                0
                                            )}
                                        />
                                    </td>
                                    <td className="number-td">
                                        <NumberValue
                                            value={sortedIndexerRows.reduce(
                                                (p, c) => p + c.total24Keys,
                                                0
                                            )}
                                        />
                                    </td>
                                    <td className="number-td">
                                        <NumberValue
                                            value={sortedIndexerRows.reduce(
                                                (p, c) => p + c.total24Msgs,
                                                0
                                            )}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="dashboard-block">
                    <h1>Feed Indexer</h1>
                    <div>
                        <table
                            className="table"
                            style={{
                                width: "100%",
                                marginTop: 20,
                            }}
                        >
                            <thead>
                                <tr>
                                    <th>Chain</th>
                                    <th>Last msg</th>
                                    <th className="number-td">Total msgs</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedFeedRows.map((row: any) => {
                                    const crit =
                                        this.feedData!.criteria[row.chain];
                                    return (
                                        <tr key={row.chain}>
                                            <td className="chain-name">
                                                {blockchainMeta[row.chain].logo(
                                                    16
                                                )}{" "}
                                                {
                                                    blockchainMeta[row.chain]
                                                        .title
                                                }
                                            </td>
                                            <td>
                                                <TimeValue
                                                    value={row.lastMsg}
                                                    criteria={crit!.lastMsg}
                                                />
                                            </td>
                                            <td className="number-td">
                                                <NumberValue
                                                    value={row.totalMsgs}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                                <tr className="total-row">
                                    <td className="chain-name">Total</td>
                                    <td></td>
                                    <td className="number-td">
                                        <NumberValue
                                            value={sortedFeedRows.reduce(
                                                (p, c) => p + c.totalMsgs,
                                                0
                                            )}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="dashboard-block">
                    <h1>Old faucet</h1>
                    {/* old_max_created_at: number | null;
                    old_total_users: number;
                    old_last_24_users: number;

                    new_max_created_at: number | null;
                    new_max_successful_at: number | null;
                    new_total_users: number;
                    new_total_successful_users: number;
                    new_last_24_users: number;
                    new_last_24_successful_users: number; */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                        }}
                    >
                        Last key publish: &nbsp;
                        <b>
                            <TimeValue
                                value={this.faucetData.data.old_max_created_at}
                                criteria={null}
                            />
                        </b>
                        , total in 24h: &nbsp;
                        <b>
                            <NumberValue
                                value={this.faucetData.data.old_last_24_users}
                            />
                        </b>
                        , total users: &nbsp;
                        <b>
                            <NumberValue
                                value={this.faucetData.data.old_total_users}
                            />
                        </b>
                    </div>
                </div>
                <div className="dashboard-block">
                    <h1>New faucet</h1>
                    {/* old_max_created_at: number | null;
                    old_total_users: number;
                    old_last_24_users: number;

                    new_max_created_at: number | null;
                    new_max_successful_at: number | null;
                    new_total_users: number;
                    new_total_successful_users: number;
                    new_last_24_users: number;
                    new_last_24_successful_users: number; */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "baseline",
                                marginBottom: 10,
                            }}
                        >
                            Last key publish request: &nbsp;
                            <b>
                                <TimeValue
                                    value={
                                        this.faucetData.data.new_max_created_at
                                    }
                                    criteria={null}
                                />
                            </b>
                            , last success: &nbsp;
                            <b>
                                <TimeValue
                                    value={
                                        this.faucetData.data
                                            .new_max_successful_at
                                    }
                                    criteria={null}
                                />
                            </b>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "baseline",
                                marginBottom: 10,
                            }}
                        >
                            Requsts in 24h: &nbsp;
                            <b>
                                <NumberValue
                                    value={
                                        this.faucetData.data.new_last_24_users
                                    }
                                />
                            </b>
                            , successful: &nbsp;
                            <b>
                                <NumberValue
                                    value={
                                        this.faucetData.data
                                            .new_last_24_successful_users
                                    }
                                />
                            </b>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "baseline",
                                marginBottom: 10,
                            }}
                        >
                            Total users: &nbsp;
                            <b>
                                <NumberValue
                                    value={this.faucetData.data.new_total_users}
                                />
                            </b>
                            , successful: &nbsp;
                            <b>
                                <NumberValue
                                    value={
                                        this.faucetData.data
                                            .new_total_successful_users
                                    }
                                />
                            </b>
                        </div>
                    </div>
                </div>
                <div className="dashboard-block">
                    <h1>Faucet balances</h1>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ textAlign: "left", padding: 6 }}>
                                    Address
                                </th>
                                <th style={{ textAlign: "left", padding: 6 }}>
                                    Gnosis
                                </th>
                                <th style={{ textAlign: "left", padding: 6 }}>
                                    Polygon
                                </th>
                                <th style={{ textAlign: "left", padding: 6 }}>
                                    Fantom
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.faucetBalances).map((addr) => {
                                return (
                                    <tr key={addr}>
                                        <td
                                            style={{
                                                fontFamily: "monospace",
                                                fontSize: 14,
                                                padding: 6,
                                            }}
                                        >
                                            {addr}
                                        </td>
                                        <td
                                            style={{
                                                padding: 6,
                                                textAlign: "right",
                                            }}
                                        >
                                            <NumberValue
                                                value={Number(
                                                    this.faucetBalances[
                                                        addr
                                                    ].GNOSIS.toFixed(2)
                                                )}
                                            />
                                        </td>
                                        <td
                                            style={{
                                                padding: 6,
                                                textAlign: "right",
                                            }}
                                        >
                                            <NumberValue
                                                value={Number(
                                                    this.faucetBalances[
                                                        addr
                                                    ].POLYGON.toFixed(2)
                                                )}
                                            />
                                        </td>
                                        <td
                                            style={{
                                                padding: 6,
                                                textAlign: "right",
                                            }}
                                        >
                                            <NumberValue
                                                value={Number(
                                                    this.faucetBalances[
                                                        addr
                                                    ].FANTOM.toFixed(2)
                                                )}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
