export const ago = (value: number) => {
    const diff = Math.floor(Date.now() / 1000) - value;
    if (diff < 60) {
        return `${diff} second${diff > 1 ? "s" : ""} ago`;
    } else if (diff < 60 * 60) {
        const v = Math.floor(diff / 60);
        return `${v} minute${v > 1 ? "s" : ""} ago`;
    } else if (diff < 60 * 60 * 24) {
        const v = Math.floor(diff / 60 / 60);
        return `${v} hour${v > 1 ? "s" : ""} ago`;
    } else {
        const v = Math.floor(diff / 60 / 60 / 24);
        return `${Math.floor(diff / 60 / 60 / 24)} day${v > 1 ? "s" : ""} ago`;
    }
};
