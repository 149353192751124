const baseUrl = document.location.href.includes("localhost")
    ? "http://localhost:3001"
    : "https://mcb.ylide.io";

export const apiGet = async (url: string, qs: Record<string, any> = {}) => {
    const response = await fetch(
        `${baseUrl}${url}?${new URLSearchParams(qs).toString()}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        }
    );
    const data = await response.json();
    return data;
};

export const apiPost = async (url: string, body: Record<string, any> = {}) => {
    const response = await fetch(`${baseUrl}${url}`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    const data = await response.json();
    return data;
};

export const getMe = async () => {
    return await apiGet("/me");
};

export const login = async (
    address: string,
    messageTimestamp: number,
    signature: string
) => {
    return await apiPost("/login", { address, messageTimestamp, signature });
};

export const logout = async () => {
    return await apiPost("/logout");
};

export const getIndexerStatus = async () => {
    return await apiGet("/indexer-status");
};

export const getFeedStatus = async () => {
    return await apiGet("/feed-status");
};

export const getFaucetStatus = async () => {
    return await apiGet("/faucet-status");
};

export const getFaucetBalances = async () => {
    return await apiGet("/faucet-balances");
};
