import { makeObservable, observable } from "mobx";
import { getMe } from "../network/api";

export class User {
    @observable loading: boolean = true;
    @observable me: null | { name: string } = null;

    constructor() {
        makeObservable(this);

        this.reload();
    }

    async reload() {
        this.loading = true;
        this.me = await getMe();
        this.loading = false;
    }
}

export const user = new User();
