import { PureComponent } from "react";
import moment from "moment";

import { ago } from "../utils/ago";
import { calcCritState } from "../utils/calcCritState";

export class TimeValue extends PureComponent<{
    value: number;
    criteria: null | [number, number];
}> {
    render() {
        const { value, criteria } = this.props;

        const neutralColor = "black";
        const badColor = "#d60000";
        const warnColor = "#df8700";
        const goodColor = "green";

        const colors: Record<"neutral" | "bad" | "warn" | "good", string> = {
            neutral: neutralColor,
            bad: badColor,
            warn: warnColor,
            good: goodColor,
        };

        const critState = calcCritState(value, criteria);

        const color = colors[critState];

        return (
            <div
                className="time-value"
                style={{ color }}
                title={
                    value
                        ? moment.unix(value).format("DD.MM HH:mm:ss")
                        : "[Nothing]"
                }
            >
                {value ? ago(value) : "[Nothing]"}
                {critState === "bad" ? ` (> ${criteria![1]}min)` : null}
                {critState === "warn" ? ` (> ${criteria![0]}min)` : null}
            </div>
        );
    }
}
