import { ArbitrumLogo } from "./icons/blockchains/ArbitrumLogo";
import { AstarLogo } from "./icons/blockchains/AstarLogo";
import { AuroraLogo } from "./icons/blockchains/AuroraLogo";
import { AvalancheLogo } from "./icons/blockchains/AvalancheLogo";
import { BNBChainLogo } from "./icons/blockchains/BNBChainLogo";
import { CeloLogo } from "./icons/blockchains/CeloLogo";
import { CronosLogo } from "./icons/blockchains/CronosLogo";
import { EthereumLogo } from "./icons/blockchains/EthereumLogo";
import { EverscaleLogo } from "./icons/blockchains/EverscaleLogo";
import { FantomLogo } from "./icons/blockchains/FantomLogo";
import { GnosisLogo } from "./icons/blockchains/GnosisLogo";
import { KlaytnLogo } from "./icons/blockchains/KlaytnLogo";
import { MetisLogo } from "./icons/blockchains/MetisLogo";
import { MoonbeamLogo } from "./icons/blockchains/MoonbeamLogo";
import { MoonriverLogo } from "./icons/blockchains/MoonriverLogo";
import { OptimismLogo } from "./icons/blockchains/OptimismLogo";
import { PolygonLogo } from "./icons/blockchains/PolygonLogo";
import { VenomLogo } from "./icons/blockchains/VenomLogo";
import { BaseLogo } from "./icons/blockchains/BaseLogo";
import { ZetaLogo } from "./icons/blockchains/ZetaLogo";
import { LineaLogo } from "./icons/blockchains/LineaLogo";

export const blockchainMeta: Record<
    string,
    {
        title: string;
        logo: (s?: number) => JSX.Element;
    }
> = {
    everscale: {
        title: "Everscale",
        logo: (s = 16) => <EverscaleLogo size={s} />,
    },
    "venom-testnet": {
        title: "Venom Testnet",
        logo: (s = 16) => <VenomLogo size={s} />,
    },
    LOCAL_HARDHAT: {
        title: "LocalNet",
        logo: (s = 16) => <EthereumLogo size={s} />,
    },
    CRONOS: {
        title: "Cronos",
        logo: (s = 16) => <CronosLogo size={s} />,
    },
    ETHEREUM: {
        title: "Ethereum",
        logo: (s = 16) => <EthereumLogo size={s} />,
    },
    BNBCHAIN: {
        title: "BNB Chain",
        logo: (s = 16) => <BNBChainLogo size={s} />,
    },
    ARBITRUM: {
        title: "Arbitrum",
        logo: (s = 16) => <ArbitrumLogo size={s} />,
    },
    AVALANCHE: {
        title: "Avalanche",
        logo: (s = 16) => <AvalancheLogo size={s} />,
    },
    OPTIMISM: {
        title: "Optimism",
        logo: (s = 16) => <OptimismLogo size={s} />,
    },
    POLYGON: {
        title: "Polygon",
        logo: (s = 16) => <PolygonLogo size={s} />,
    },
    FANTOM: {
        title: "Fantom",
        logo: (s = 16) => <FantomLogo size={s} />,
    },
    KLAYTN: {
        title: "Klaytn",
        logo: (s = 16) => <KlaytnLogo size={s} />,
    },
    GNOSIS: {
        title: "Gnosis",
        logo: (s = 16) => <GnosisLogo size={s} />,
    },
    AURORA: {
        title: "Aurora",
        logo: (s = 16) => <AuroraLogo size={s} />,
    },
    CELO: {
        title: "Celo",
        logo: (s = 16) => <CeloLogo size={s} />,
    },
    MOONBEAM: {
        title: "Moonbeam",
        logo: (s = 16) => <MoonbeamLogo size={s} />,
    },
    MOONRIVER: {
        title: "Moonriver",
        logo: (s = 16) => <MoonriverLogo size={s} />,
    },
    METIS: {
        title: "Metis",
        logo: (s = 16) => <MetisLogo size={s} />,
    },
    ASTAR: {
        title: "Astar",
        logo: (s = 16) => <AstarLogo size={s} />,
    },
    BASE: {
        title: "Base",
        logo: (s = 16) => <BaseLogo size={s} />,
    },
    ZETA: {
        title: "Zeta",
        logo: (s = 16) => <ZetaLogo size={s} />,
    },
    LINEA: {
        title: "Linea",
        logo: (s = 16) => <LineaLogo size={s} />,
    },
};
