import React from "react";
import ReactDOM from "react-dom/client";

import "./index.scss";
import { Entry } from "./pages/Entry";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    // <React.StrictMode>
    <Entry />
    // </React.StrictMode>
);
