export const calcCritState = (
    value: null | number,
    crit: null | [number, number]
) => {
    if (!value || !crit) {
        return "neutral" as const;
    }
    const val = Date.now() - value * 1000;
    if (val > crit[1] * 60 * 1000) {
        return "bad" as const;
    }
    if (val > crit[0] * 60 * 1000) {
        return "warn" as const;
    }
    return "good" as const;
};
