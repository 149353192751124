import { PureComponent } from "react";
import { observer } from "mobx-react";
import { user } from "../stores/user";
import { Dashboard } from "./Dashboard";
import { login } from "../network/api";

@observer
export class Entry extends PureComponent {
    render() {
        if (user.loading) {
            return (
                <div>
                    <h1>Loading</h1>
                </div>
            );
        } else if (user.me) {
            return <Dashboard />;
        } else {
            return (
                <div>
                    <h1>Not logged in</h1>
                    <div>
                        <button
                            onClick={async () => {
                                const accounts = await (
                                    window as any
                                ).ethereum.request({
                                    method: "eth_requestAccounts",
                                });
                                const account =
                                    (accounts ? accounts[0] : null) || null;
                                if (!account) {
                                    return;
                                }
                                console.log("account: ", account);
                                const messageTimestamp = Date.now();
                                const ethAddress = account;
                                const message = `Ylide authorization, ${ethAddress}, timestamp: ${messageTimestamp}`;
                                const signature = await (
                                    window as any
                                ).ethereum.request({
                                    method: "personal_sign",
                                    params: [message, account],
                                });
                                console.log("signature: ", signature);
                                await login(
                                    ethAddress,
                                    messageTimestamp,
                                    signature
                                );
                                await user.reload();
                            }}
                        >
                            Log in
                        </button>
                    </div>
                </div>
            );
        }
    }
}
